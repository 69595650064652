/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */
import type { RouteRecordName, RouteRecordRaw } from 'vue-router'
import type { VabRouteRecord } from '/#/router'
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'
import Layout from '@vab/layouts/index.vue'
import { setupPermissions } from './permissions'
import { authentication, isHashRouterMode, publicPath } from '@/config'

export const constantRoutes: VabRouteRecord[] = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/index.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      hidden: true,
    },
  },
]

export const asyncRoutes: VabRouteRecord[] = [
  {
    path: '/',
    name: 'Root',
    component: Layout,
    meta: {
      title: '仪表盘',
      icon: 'pie-chart-2-fill',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/dashboard/index.vue'),
        meta: {
          title: '数据看板',
          noClosable: true,
          noKeepAlive: true,
        },
      },
      {
        path: 'index/family',
        name: 'userFamily',
        component: () => import('@/views/dashboard/family/index.vue'),
        meta: {
          title: '家庭总览',
          noClosable: true,
          noKeepAlive: true,
        },
      },
      {
        path: 'index/user/overview',
        name: 'userOverview',
        component: () => import('@/views/dashboard/useroverview/index.vue'),
        meta: {
          title: '用户总览',
          noClosable: true,
          noKeepAlive: true,
        },
      },
      {
        path: 'index/user',
        name: 'IndexUser',
        component: () => import('@/views/dashboard/user/index.vue'),
        meta: {
          title: '活跃分析',
          noClosable: true,
          noKeepAlive: true,
        },
      },
      {
        path: 'remain/index',
        name: 'RemainIndex',
        component: () => import('@/views/dashboard/remain/index.vue'),
        meta: {
          title: '留存分析',
          noClosable: true,
          noKeepAlive: true,
        },
      },
      {
        path: 'index/device',
        name: 'IndexDevice',
        component: () => import('@/views/dashboard/device/index.vue'),
        meta: {
          title: '智能设备',
          noClosable: true,
          noKeepAlive: true,
        },
      },
      {
        path: 'index/wecom',
        name: 'IndexWecom',
        component: () => import('@/views/dashboard/wecom/index.vue'),
        meta: {
          title: '企微服务',
          noClosable: true,
          noKeepAlive: true,
        },
      },
      {
        path: 'index/nutrition',
        name: 'IndexNutrition',
        component: () => import('@/views/dashboard/nutrition/index.vue'),
        meta: {
          title: '营养管理',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'index/nutrition',
            name: 'IndexNutrition',
            component: () => import('@/views/dashboard/nutrition/index.vue'),
            meta: {
              title: '营养管理',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'index/nutrition/good',
            name: 'IndexNutritionGood',
            component: () =>
              import('@/views/dashboard/nutrition/goods/index.vue'),
            meta: {
              title: '商品曝光',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'index/application',
        name: 'IndexApplication',
        component: () => import('@/views/dashboard/application/index.vue'),
        meta: {
          title: '应用统计',
          noClosable: true,
          noKeepAlive: true,
        },
      },
      {
        path: 'index/newbietask',
        name: 'IndexNewbietask',
        component: () => import('@/views/dashboard/newbietask/index.vue'),
        meta: {
          title: '新手任务',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'index/newbietask',
            name: 'IndexNewbietask',
            component: () => import('@/views/dashboard/newbietask/index.vue'),
            meta: {
              title: '活动数据',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'newbieTaskList',
            name: 'newbieTaskList',
            component: () =>
              import('@/views/dashboard/newbietask/newbieTask/index.vue'),
            meta: {
              title: '活动用户',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'newbieTaskDetail/:id',
            name: 'newbieTaskDetail',
            component: () =>
              import('@/views/dashboard/newbietask/newbieTask/detail.vue'),
            meta: {
              title: '活动用户详情',
              noClosable: false,
              noKeepAlive: true,
              hidden: true,
            },
          },
        ],
      },
      {
        path: 'index/integralTask',
        name: 'IndexIntegralTask',
        component: () => import('@/views/dashboard/integralTask/index.vue'),
        meta: {
          title: '积分任务',
          noClosable: true,
          noKeepAlive: true,
        },
      },
      {
        path: 'index/fhr',
        name: 'IndexFhr',
        component: () => import('@/views/dashboard/fhr/index.vue'),
        meta: {
          title: '胎心仪',
          noClosable: true,
          noKeepAlive: true,
        },
      },
      {
        path: 'index/milkbag',
        name: 'IndexMilkbag',
        component: () => import('@/views/dashboard/milkbag/index.vue'),
        meta: {
          title: '储奶袋',
          noClosable: true,
          noKeepAlive: true,
        },
      },
    ],
  },
  {
    path: '/userManage',
    name: 'UserManage',
    component: Layout,
    meta: {
      title: '用户管理',
      breadcrumbHidden: true,
      icon: 'file-user-fill',
    },
    children: [
      {
        path: 'list',
        name: 'UserManageList',
        component: () => import('@/views/userManage/index.vue'),
        meta: {
          title: '用户列表',
          noClosable: false,
          noKeepAlive: false,
        },
      },
      {
        path: 'familyList',
        name: 'FamilyList',
        component: () => import('@/views/userManage/familyList.vue'),
        meta: {
          title: '家庭列表',
          noClosable: false,
          noKeepAlive: false,
        },
      },
      {
        path: 'familyDetail/:id',
        name: 'FamilyDetail',
        component: () => import('@/views/userManage/familyDetail.vue'),
        meta: {
          hidden: true,
          title: '家庭详情',
          noClosable: false,
          noColumn: false,
          noKeepAlive: true,
          activeMenu: '/userManage/familyList',
        },
      },
      {
        path: 'detail/:id',
        name: 'UserManageDetail',
        component: () => import('@/views/userManage/detail.vue'),
        meta: {
          hidden: true,
          title: '用户详情',
          noClosable: false,
          noColumn: false,
          noKeepAlive: true,
          activeMenu: '/userManage/list',
        },
      },
      {
        path: 'baby',
        name: 'UserManageBaby',
        component: () => import('@/views/userManage/baby.vue'),
        meta: {
          title: '宝宝列表',
          noClosable: false,
          noKeepAlive: false,
        },
      },
      {
        path: 'bindMate',
        name: 'UserManageBindMate',
        component: () => import('@/views/userManage/bindMate.vue'),
        meta: {
          title: '伴侣列表',
          noClosable: false,
          noKeepAlive: false,
        },
      },
      {
        path: 'loginLog',
        name: 'LoginLog',
        component: () => import('@/views/userManage/loginLog.vue'),
        meta: {
          title: '登录日志',
          noClosable: false,
          noKeepAlive: true,
        },
      },
      {
        path: 'wecomStaffList',
        name: 'wecomStaffList',
        component: () => import('@/views/userManage/wecomStaffList.vue'),
        meta: {
          title: '企微员工',
          noClosable: false,
          noKeepAlive: true,
        },
      },
      {
        path: 'wecomList',
        name: 'wecomList',
        component: () => import('@/views/userManage/wecomList.vue'),
        meta: {
          title: '企微用户',
          noClosable: false,
          noKeepAlive: true,
        },
      },
      {
        path: 'vantList',
        name: 'vantList',
        component: () => import('@/views/userManage/vantList.vue'),
        meta: {
          title: '有赞用户',
          noClosable: false,
          noKeepAlive: true,
        },
      },
    ],
  },
  {
    path: '/operationManage',
    name: 'OperationManage',
    component: Layout,
    meta: {
      title: '私域配置',
      icon: 'folder-chart-fill',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: 'groupManage',
        name: 'GroupManageList',
        component: () =>
          import('@/views/operationManage/groupManage/groupManageList.vue'),
        meta: {
          title: '社群管理列表',
          noClosable: false,
          noKeepAlive: true,
        },
      },
      {
        path: '/groupManage/edit',
        name: 'EditGroupManage',
        component: () =>
          import('@/views/operationManage/groupManage/editGroupManage.vue'),
        meta: {
          title: '添加同城同阶段同期社群',
          hidden: true,
          activeMenu: '/groupManage',
          noClosable: false,
          noKeepAlive: true,
        },
      },
      {
        path: 'wechat',
        name: 'WechatQRcode',
        component: () =>
          import('@/views/operationManage/wechat/qrcodeList.vue'),
        meta: {
          title: '企微二维码列表',
          noClosable: false,
          noKeepAlive: true,
        },
      },
      {
        path: 'pointsHomepage',
        name: 'pointsHomepage',
        component: () => import('@/views/pointsHomepage/index.vue'),
        meta: {
          title: '有赞配置',
          noClosable: false,
          noKeepAlive: true,
        },
      },
    ],
  },
  {
    path: '/userApplication',
    name: 'userApplication',
    component: Layout,
    meta: {
      title: '应用',
      icon: 'apps-fill',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: 'milkbag',
        name: 'milkbag',
        component: Layout,
        // component: () => import('@/views/smalltool/vaccine/index.vue'),
        meta: {
          title: '储奶袋',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'milkbagList',
            name: 'milkbagList',
            component: () =>
              import('@/views/application/milkbag/deviceList/index.vue'),
            meta: {
              title: '储奶用户',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'milkbagDataList',
            name: 'milkbagDataList',
            component: () =>
              import('@/views/application/milkbag/dataList/index.vue'),
            meta: {
              title: '记录列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'milkbagStockList',
            name: 'milkbagStockList',
            component: () =>
              import('@/views/application/milkbag/stockList/index.vue'),
            meta: {
              title: '库存列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'fhr',
        name: 'fhr',
        component: Layout,
        // component: () => import('@/views/smalltool/vaccine/index.vue'),
        meta: {
          title: '胎心仪',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'fhrDeviceList',
            name: 'fhrDeviceList',
            component: () =>
              import('@/views/application/fhr/deviceList/index.vue'),
            meta: {
              title: '设备列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'fhrDataList',
            name: 'fhrDataList',
            component: () =>
              import('@/views/application/fhr/dataList/index.vue'),
            meta: {
              title: '数据列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },

      {
        path: 'nutrient',
        name: 'nutrient',
        component: Layout,
        // component: () => import('@/views/smalltool/vaccine/index.vue'),
        meta: {
          title: '营养管理',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'nutrientRecord',
            name: 'nutrientRecord',
            component: () =>
              import('@/views/application/nutrient/record/index.vue'),
            meta: {
              title: '用户饮食记录',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'nutrientRecordDetail',
            name: 'nutrientRecordDetail',
            component: () =>
              import('@/views/application/nutrient/record/detail.vue'),
            meta: {
              title: '记录详情',
              noClosable: false,
              noKeepAlive: true,
              hidden: true,
            },
          },
          {
            path: 'nutrientSearch',
            name: 'nutrientSearch',
            component: () =>
              import('@/views/application/nutrient/search/index.vue'),
            meta: {
              title: '用户搜索记录',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'nutrientScan',
            name: 'nutrientScan',
            component: () =>
              import('@/views/application/nutrient/scan/index.vue'),
            meta: {
              title: '用户扫码记录',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'growth',
        name: 'growth',
        component: Layout,
        meta: {
          title: '成长记录',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'growthList',
            name: 'growthList',
            component: () => import('@/views/application/growth/index.vue'),
            meta: {
              title: '记录列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'drink',
        name: 'drink',
        component: Layout,
        meta: {
          title: '喝水管理',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'drinkList',
            name: 'drinkList',
            component: () => import('@/views/application/drink/index.vue'),
            meta: {
              title: '喝水列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'habit',
        name: 'habit',
        component: Layout,
        meta: {
          title: '习惯打卡',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'habitList',
            name: 'habitList',
            component: () => import('@/views/application/habit/index.vue'),
            meta: {
              title: '打卡列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'symptom',
        name: 'symptom',
        component: Layout,
        meta: {
          title: '不适症状',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'symptomList',
            name: 'symptomList',
            component: () => import('@/views/application/symptom/index.vue'),
            meta: {
              title: '症状列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'contraction',
        name: 'contraction',
        component: Layout,
        meta: {
          title: '宫缩记录',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'contractionList',
            name: 'contractionList',
            component: () =>
              import('@/views/application/contraction/index.vue'),
            meta: {
              title: '宫缩列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: '/baby',
        name: 'Baby1',
        meta: {
          title: '胎宝宝变化',
        },
        children: [
          {
            path: 'estimate',
            name: 'stimate',
            component: () => import('@/views/weight/baby.vue'),
            meta: {
              title: '估算记录',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      // {
      //   path: 'check',
      //   name: 'SmalltoolCheck1',
      //   // component: Layout,
      //   // component: () => import('@/views/smalltool/check/index.vue'),
      //   meta: {
      //     title: '孕期产检',
      //     noClosable: false,
      //     noKeepAlive: true,
      //   },
      //   children: [
      //     {
      //       path: 'list',
      //       name: 'SmalltoolCheckList',
      //       component: () => import('@/views/smalltool/check/list/index.vue'),
      //       meta: {
      //         title: '产检列表',
      //         noClosable: false,
      //         noKeepAlive: true,
      //       },
      //     },
      //     {
      //       path: 'babyCheckList',
      //       name: 'SmalltoolbabyCheckList',
      //       component: () =>
      //         import('@/views/smalltool/check/list/babyCheckList.vue'),
      //       meta: {
      //         hidden: true,
      //         title: '宝宝产检档案',
      //         noClosable: false,
      //         noKeepAlive: true,
      //       },
      //     },
      //   ],
      // },
      {
        path: 'vaccine',
        name: 'Vaccine1',
        component: Layout,
        meta: {
          title: '宝宝疫苗',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'vaccineList',
            name: 'vaccineList',
            component: () => import('@/views/application/vaccine/index.vue'),
            meta: {
              title: '记录列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          // {
          //   path: 'babyVaccine',
          //   name: 'babyVaccine',
          //   component: () =>
          //     import('@/views/smalltool/vaccine/babyVaccine/index.vue'),
          //   meta: {
          //     title: '宝宝月龄疫苗',
          //     noClosable: false,
          //     noKeepAlive: true,
          //   },
          // },
          {
            path: 'babyVaccineList',
            name: 'babyVaccineList',
            component: () =>
              import(
                '@/views/smalltool/vaccine/babyVaccine/babyVaccineList.vue'
              ),
            meta: {
              hidden: true,
              title: '宝宝疫苗',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'package',
        name: 'package',
        component: Layout,
        meta: {
          title: '待产包',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'packageList',
            name: 'packageList',
            component: () => import('@/views/application/package/index.vue'),
            meta: {
              title: '待产包列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'weight',
        name: 'weight',
        component: Layout,
        meta: {
          title: '体重管理',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'weightList',
            name: 'weightList',
            component: () => import('@/views/application/weight/index.vue'),
            meta: {
              title: '体重测量列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'pregnantInspect',
        name: 'pregnantInspect',
        component: Layout,
        meta: {
          title: '孕前检查',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'pregnantInspectList',
            name: 'pregnantInspectList',
            component: () =>
              import('@/views/application/pregnantInspect/index.vue'),
            meta: {
              title: '检查报告列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'fetalMovement',
        name: 'fetalMovement',
        component: Layout,
        meta: {
          title: '数胎动',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'fetalMovementList',
            name: 'fetalMovementList',
            component: () =>
              import('@/views/application/fetalMovement/index.vue'),
            meta: {
              title: '数胎动列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'productionCheck',
        name: 'productionCheck',
        component: Layout,
        meta: {
          title: '产检档案',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'productionCheckList',
            name: 'productionCheckList',
            component: () =>
              import('@/views/application/productionCheck/index.vue'),
            meta: {
              title: '产检档案列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'sensation',
        name: 'sensation',
        component: Layout,
        meta: {
          title: '经期感受',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'sensationList',
            name: 'sensationList',
            component: () => import('@/views/application/sensation/index.vue'),
            meta: {
              title: '经期感受列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'feed',
        name: 'feed',
        component: Layout,
        meta: {
          title: '喂养记录',
          noClosable: false,
          noKeepAlive: true,
        },
        children: [
          {
            path: 'feedList',
            name: 'feedList',
            component: () => import('@/views/application/feed/index.vue'),
            meta: {
              title: '喂养记录列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/setup',
    name: 'setup',
    component: Layout,
    meta: {
      title: '设置',
      icon: 'hammer-fill',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/remind',
        name: 'remind',
        meta: {
          title: '今日提醒',
        },
        children: [
          {
            path: '/remindSetup',
            name: 'remindSetup',
            component: () => import('@/views/remind/setup/index.vue'),
            meta: {
              title: '提醒设置',
            },
          },
          {
            path: '/remindList',
            name: 'remindList',
            component: () => import('@/views/remind/list/index.vue'),
            meta: {
              title: '提醒列表',
              noClosable: false,
              noKeepAlive: false,
            },
          },
          {
            path: '/remindEdit',
            name: 'remindEdit',
            component: () =>
              import('@/views/remind/list/components/EditCategory.vue'),
            meta: {
              title: '编辑提醒',
              hidden: true,
            },
          },
        ],
      },
      {
        path: '/kf',
        name: 'Kf',
        meta: {
          title: '客服管理',
        },
        children: [
          {
            path: '/kf/list',
            name: 'kfList',
            component: () => import('@/views/kf/index.vue'),
            meta: {
              title: '客服列表',
              noClosable: false,
              noKeepAlive: false,
            },
          },
        ],
      },
      {
        path: '/youzan',
        name: 'Youzan',
        meta: {
          title: '有赞管理',
        },
        children: [
          {
            path: '/youzan/good',
            name: 'goodList',
            component: () => import('@/views/youzan/good/index.vue'),
            meta: {
              title: '有赞商品',
              noClosable: false,
              noKeepAlive: false,
            },
          },
        ],
      },
      {
        path: '/qa',
        name: 'qa',
        meta: {
          title: '问答管理',
        },
        children: [
          {
            path: '/qa/category',
            name: 'categoryList',
            component: () => import('@/views/qa/category/index.vue'),
            meta: {
              title: '分类管理',
              noClosable: false,
              noKeepAlive: false,
            },
          },
          {
            path: '/qa/list',
            name: 'QaList',
            component: () => import('@/views/qa/list/index.vue'),
            meta: {
              title: '问答列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: '/classroom',
        name: 'classroom',
        meta: {
          title: '课程管理',
        },
        children: [
          {
            path: '/classroom/category',
            name: 'classroomcategoryList',
            component: () => import('@/views/classroom/category/index.vue'),
            meta: {
              title: '分类管理',
              noClosable: false,
              noKeepAlive: false,
            },
          },
          {
            path: '/classroom/list',
            name: 'ClassroomList',
            component: () => import('@/views/classroom/list/index.vue'),
            meta: {
              title: '课程列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: '/food',
        name: 'Food',
        meta: {
          title: '营养管理',
        },
        children: [
          {
            path: 'list',
            name: 'FoodList',
            component: () => import('@/views/food/foodList/index.vue'),
            meta: {
              title: '食物管理',
              noClosable: false,
              noKeepAlive: false,
            },
            children: [
              {
                path: 'list',
                name: 'FoodList',
                component: () => import('@/views/food/foodList/index.vue'),
                meta: {
                  title: '食物列表',
                  noClosable: false,
                  noKeepAlive: false,
                },
              },
              {
                path: 'uploadList',
                name: 'uploadList',
                component: () =>
                  import('@/views/food/foodList/uploadList/index.vue'),
                meta: {
                  title: '用户上传食物',
                  noClosable: false,
                  noKeepAlive: false,
                },
              },
            ],
          },
          {
            path: 'category',
            name: 'FoodCategory',
            component: () => import('@/views/food/category/index.vue'),
            meta: {
              title: '食物分类',
              noClosable: false,
              noKeepAlive: true,
            },
          },

          {
            path: 'edit',
            name: 'EditFood',
            component: () => import('@/views/food/foodList/edit.vue'),
            meta: {
              title: '添加食物',
              hidden: true,
              activeMenu: '/food/list',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: '/nutrient',
            name: 'Nutrient',
            meta: {
              title: '营养建议',
            },
            children: [
              {
                path: 'unit',
                name: 'Unit',
                component: () => import('@/views/nutrient/unit/index.vue'),
                meta: {
                  title: '营养元素管理',
                  noClosable: false,
                  noKeepAlive: false,
                },
              },
              // {
              //   path: 'unit/intro',
              //   name: 'UnitIntro',
              //   component: () => import('@/views/nutrient/unit/intro.vue'),
              //   meta: {
              //     title: '用户阶段介绍',
              //     hidden: true,
              //     activeMenu: '/nutrient/unit',
              //     noClosable: false,
              //     noKeepAlive: true,
              //   },
              // },
              {
                path: 'unit/crowdSetting',
                name: 'UnitCrowdSetting',
                component: () =>
                  import('@/views/nutrient/unit/crowdSetting.vue'),
                meta: {
                  title: '人群元素设置',
                  hidden: true,
                  activeMenu: '/nutrient/unit',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
              {
                path: 'unit/food',
                name: 'UnitFood',
                component: () => import('@/views/nutrient/unit/food.vue'),
                meta: {
                  title: '关联食物',
                  hidden: true,
                  activeMenu: '/nutrient/unit',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
              {
                path: 'set',
                name: 'Set',
                component: () => import('@/views/nutrient/unit/index.vue'),
                meta: {
                  title: '相关设置',
                  noClosable: false,
                  noKeepAlive: true,
                },
                children: [
                  {
                    path: 'id/1',
                    name: 'pregnant',
                    component: () => import('@/views/nutrient/set/edit.vue'),
                    meta: {
                      title: '备孕',
                    },
                  },
                  {
                    path: 'id/2',
                    name: 'early_pregnancy',
                    component: () => import('@/views/nutrient/set/edit.vue'),
                    meta: {
                      title: '孕早',
                    },
                  },
                  {
                    path: 'id/3',
                    name: 'in_pregnancy',
                    component: () => import('@/views/nutrient/set/edit.vue'),
                    meta: {
                      title: '孕中',
                    },
                  },
                  {
                    path: 'id/4',
                    name: 'night_pregnancy',
                    component: () => import('@/views/nutrient/set/edit.vue'),
                    meta: {
                      title: '孕晚',
                    },
                  },
                  {
                    path: 'id/5',
                    name: 'postpartum',
                    component: () => import('@/views/nutrient/set/edit.vue'),
                    meta: {
                      title: '产后',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: '/movement',
            name: 'Movement',
            meta: {
              title: '运动建议',
            },
            children: [
              {
                path: 'list',
                name: 'MovementList',
                component: () => import('@/views/movement/list/index.vue'),
                meta: {
                  title: '运动列表',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
              {
                path: 'intro',
                name: 'MovementIntro',
                component: () => import('@/views/movement/intro.vue'),
                meta: {
                  title: '用户阶段配置',
                  hidden: true,
                  activeMenu: '/movement/list',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
              {
                path: 'set',
                name: 'MovementSet',
                component: () => import('@/views/movement/set.vue'),
                meta: {
                  title: '相关设置',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: '/message',
        name: 'message',
        meta: {
          title: '消息平台管理',
        },
        children: [
          {
            path: 'task',
            name: 'messagTask',
            component: () => import('@/views/messagePlatform/task/index.vue'),
            meta: {
              title: '消息任务列表',
              noClosable: false,
              noKeepAlive: false,
            },
          },
          {
            path: 'template',
            name: 'messageTemplate',
            component: () =>
              import('@/views/messagePlatform/template/index.vue'),
            meta: {
              title: '消息模板列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'channel_account',
            name: 'messageChannelAccount',
            component: () =>
              import('@/views/messagePlatform/channel/index.vue'),
            meta: {
              title: '渠道账号列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: '/activity',
        name: 'activity',
        meta: {
          title: '活动管理',
        },
        children: [
          {
            path: 'noviceTask',
            name: 'noviceTask',
            meta: {
              title: '新手任务活动',
            },
            children: [
              {
                path: 'list',
                name: 'noviceTaskAtivityList',
                component: () =>
                  import('@/views/noviceTask/activity/index.vue'),
                meta: {
                  title: '活动列表',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
              {
                path: 'detail/:id',
                name: 'noviceTaskAtivityDatail',
                component: () =>
                  import('@/views/noviceTask/activity/detail.vue'),
                meta: {
                  title: '活动详情',
                  hidden: true,
                  activeMenu: '/activity/noviceTask/list',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
              {
                path: 'task',
                name: 'noviceSystemTaskList',
                component: () => import('@/views/noviceTask/task/index.vue'),
                meta: {
                  title: '系统任务列表',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
              {
                path: 'awards',
                name: 'noviceTaskAwardsList',
                component: () => import('@/views/noviceTask/awards/index.vue'),
                meta: {
                  title: '系统奖品列表',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: '/weight',
        name: 'Weight',
        meta: {
          title: '体重管理',
        },
        children: [
          {
            path: 'data',
            name: 'WeightData',
            component: () => import('@/views/weight/data/index.vue'),
            meta: {
              title: '体重测量列表',
              noClosable: false,
              noKeepAlive: false,
            },
          },
          {
            path: 'data/edit',
            name: 'DataEdit',
            component: () => import('@/views/weight/data/edit.vue'),
            meta: {
              title: '体重测量详情',
              activeMenu: '/weight/data',
              noClosable: false,
              hidden: true,
              noKeepAlive: false,
            },
          },
          {
            path: 'werun',
            name: 'WeightWerun',
            component: () => import('@/views/weight/werun.vue'),
            meta: {
              title: '用户运动步数',
              noClosable: false,
              noKeepAlive: false,
            },
          },
          {
            path: 'suggest',
            name: 'WeightSuggest',
            component: () => import('@/views/weight/suggest/list.vue'),
            meta: {
              title: '指标建议',
              noClosable: false,
              noKeepAlive: false,
            },
          },
          {
            path: 'intro',
            name: 'WeightSuggestIntro',
            component: () => import('@/views/weight/suggest/intro.vue'),
            meta: {
              title: '饮食建议',
              hidden: true,
              activeMenu: '/weight/suggest',
              noClosable: false,
              noKeepAlive: false,
            },
          },
          {
            path: 'report',
            name: 'WeightReport',
            component: () => import('@/views/weight/report/index.vue'),
            meta: {
              title: '报告列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'report/edit',
            name: 'ReportEdit',
            component: () => import('@/views/weight/report/edit.vue'),
            meta: {
              title: '报告详情',
              activeMenu: '/weight/report',
              noClosable: false,
              hidden: true,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: '/device',
        name: 'Device',
        // component: Layout,
        meta: {
          title: '设备管理',
          // icon: 'folder-chart-fill',
          breadcrumbHidden: true,
        },
        children: [
          {
            path: 'category',
            name: 'DeviceCategory',
            component: () => import('@/views/device/category/index.vue'),
            meta: {
              title: '设备分类列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'list',
            name: 'DeviceList',
            component: () => import('@/views/device/list/index.vue'),
            meta: {
              title: '绑定设备列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'list/edit',
            name: 'ListEdit',
            component: () => import('@/views/device/list/edit.vue'),
            meta: {
              title: '绑定设备详情',
              hidden: true,
              activeMenu: '/device/list',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'type',
            name: 'DeviceType',
            component: () => import('@/views/device/type/index.vue'),
            meta: {
              title: '设备类型',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'batch',
            name: 'DeviceBatch',
            component: () => import('@/views/device/batch/index.vue'),
            meta: {
              title: '设备批次管理',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: '/question',
        name: 'Question',
        meta: {
          title: '问题管理',
          breadcrumbHidden: true,
        },
        children: [
          {
            path: 'category',
            name: 'QuestionCategory',
            component: () =>
              import('@/views/systemData/question/category/index.vue'),
            meta: {
              title: '问题分类列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'list',
            name: 'QuestionList',
            component: () =>
              import('@/views/systemData/question/list/index.vue'),
            meta: {
              title: '问题列表',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
      {
        path: 'application',
        name: 'application',
        meta: {
          title: '应用管理',
        },
        children: [
          {
            path: '/baby',
            name: 'Baby',
            meta: {
              title: '胎宝宝变化',
            },
            children: [
              {
                path: 'weight',
                name: 'BabyBaby',
                component: () => import('@/views/systemData/baby/index.vue'),
                meta: {
                  title: '不同重量参照物',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
            ],
          },
          {
            path: 'check',
            name: 'SmalltoolCheck',
            meta: {
              title: '孕期产检',
              noClosable: false,
              noKeepAlive: true,
            },
            children: [
              {
                path: 'projectList',
                name: 'SmalltoolProjectList',
                component: () =>
                  import('@/views/smalltool/check/projectList/index.vue'),
                meta: {
                  title: '产检项目',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
              {
                path: 'projectListEdit',
                name: 'SmalltoolProjectListEdit',
                component: () =>
                  import('@/views/smalltool/check/projectList/edit.vue'),
                meta: {
                  hidden: true,
                  title: '编辑项目',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
              {
                path: 'system',
                name: 'SmalltoolCheckSystem',
                component: () =>
                  import('@/views/smalltool/check/system/index.vue'),
                meta: {
                  title: '基础产检',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
              {
                path: 'systemCheckEdit',
                name: 'SmalltoolCheckSystemEdit',
                component: () =>
                  import('@/views/smalltool/check/system/edit.vue'),
                meta: {
                  hidden: true,
                  title: '编辑产检',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
            ],
          },
          {
            path: 'vaccine',
            name: 'Vaccine',
            component: Layout,
            meta: {
              title: '宝宝疫苗',
              noClosable: false,
              noKeepAlive: true,
            },
            children: [
              {
                path: 'list',
                name: 'ListVaccine',
                component: () => import('@/views/smalltool/vaccine/index.vue'),
                meta: {
                  title: '疫苗列表',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
              {
                path: 'monthList',
                name: 'ListMonthList',
                component: () =>
                  import('@/views/smalltool/vaccine/monthList.vue'),
                meta: {
                  title: '疫苗月龄列表',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
              {
                path: 'edit',
                name: 'EditVaccine',
                component: () => import('@/views/smalltool/vaccine/edit.vue'),
                meta: {
                  title: '疫苗详情',
                  hidden: true,
                  activeMenu: '/smalltool/vaccine/list',
                  noClosable: false,
                  noKeepAlive: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: '/systemData',
        name: 'SystemData',
        meta: {
          title: '系统设置',
          breadcrumbHidden: true,
        },
        children: [
          {
            path: 'version',
            name: 'SystemDataVersion',
            component: () => import('@/views/systemData/version/index.vue'),
            meta: {
              title: '版本管理',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'version/edit',
            name: 'EditVersion',
            component: () => import('@/views/systemData/version/edit.vue'),
            meta: {
              title: '添加版本',
              hidden: true,
              activeMenu: '/systemData/version',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'miniapp',
            name: 'SystemMiniapp',
            component: () => import('@/views/systemData/miniapp/index.vue'),
            meta: {
              title: '平台管理',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'miniapp/edit',
            name: 'EditMiniapp',
            component: () => import('@/views/systemData/miniapp/edit.vue'),
            meta: {
              title: '添加',
              hidden: true,
              activeMenu: '/systemData/miniapp',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'labelManage',
            name: 'labelManage',
            component: () => import('@/views/userManage/labelManage.vue'),
            meta: {
              title: '标签管理',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'promptTemplate',
            name: 'SystemDataPromptTemplate',
            component: () =>
              import('@/views/systemData/promptTemplate/index.vue'),
            meta: {
              title: '文案模板',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'article',
            name: 'SystemArticle',
            component: () => import('@/views/systemData/article/index.vue'),
            meta: {
              title: '内容管理',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'advertisement',
            name: 'SystemAdvertisement',
            component: () =>
              import('@/views/systemData/advertisement/index.vue'),
            meta: {
              title: '广告图管理',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'intro',
            name: 'SystemDataIntro',
            component: () => import('@/views/systemData/intro/index.vue'),
            meta: {
              title: '相关说明',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'systemSetting',
            name: 'systemSetting',
            component: () => import('@/views/setting/systemSetting/index.vue'),
            meta: {
              title: '系统数据',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'intro/edit',
            name: 'EditIntro',
            component: () => import('@/views/systemData/intro/edit.vue'),
            meta: {
              title: '添加图文',
              hidden: true,
              activeMenu: '/systemData/intro',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'queue',
            name: 'SystemDataQueue',
            component: () => import('@/views/systemData/queue/index.vue'),
            meta: {
              title: '队列管理',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'picture',
            name: 'PictureManagement',
            component: () =>
              import('@/views/systemData/pictureManagement/index.vue'),
            meta: {
              title: '素材管理',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'set',
            name: 'StatusSet',
            component: () => import('@/views/userManage/set.vue'),
            meta: {
              title: '各阶段设置',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'cycle',
            name: 'SystemDataCycle',
            component: () => import('@/views/systemData/cycle/index.vue'),
            meta: {
              title: '怀孕周期管理',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'disease',
            name: 'SystemDataDisease',
            component: () => import('@/views/systemData/disease/index.vue'),
            meta: {
              title: '孕妇特殊情况',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'knowledge',
            name: 'SystemDataKnowledge',
            component: () => import('@/views/systemData/knowledge/index.vue'),
            meta: {
              title: '科普常识管理',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'pushRemind',
            name: 'PushRemind',
            component: () => import('@/views/userManage/pushRemind.vue'),
            meta: {
              title: '消息推送设置',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'template',
            name: 'SystemDataTemplate',
            component: () => import('@/views/systemData/template/index.vue'),
            meta: {
              title: '订阅消息模板',
              noClosable: false,
              noKeepAlive: true,
            },
          },
          {
            path: 'template/log',
            name: 'TemplateLog',
            component: () => import('@/views/systemData/template/log.vue'),
            meta: {
              title: '用户订阅记录',
              hidden: true,
              noClosable: false,
              activeMenu: '/systemData/template',
              noKeepAlive: true,
            },
          },
          {
            path: 'feedback',
            name: 'SystemFeedback',
            component: () => import('@/views/systemData/feedback/index.vue'),
            meta: {
              title: '意见反馈管理',
              noClosable: false,
              noKeepAlive: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/components/UploadPictures',
    name: 'UploadPictures',
    component: () => import('@/components/uploadPictures/UploadPictures.vue'),
    meta: {
      title: '素材选择器',
      hidden: true,
    },
  },
  // {
  //   path: '/setting',
  //   name: 'setting',
  //   component: Layout,
  //   meta: {
  //     title: '设置管理',
  //     icon: 'settings-3-fill',
  //     breadcrumbHidden: true,
  //   },
  //   children: [
  //     {
  //       path: 'systemSetting',
  //       name: 'systemSetting',
  //       component: () => import('@/views/setting/systemSetting/index.vue'),
  //       meta: {
  //         title: '系统设置',
  //         noClosable: false,
  //         noKeepAlive: true,
  //       },
  //     },
  //   ],
  // },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Layout,
    meta: {
      title: '维护',
      icon: 'tools-fill',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: 'classification',
        name: 'developmentClassification',
        component: () =>
          import('@/views/maintenance/developmentConfig/index.vue'),
        meta: {
          title: '配置分类',
        },
      },
      {
        path: 'configList',
        name: 'developmentConfigList',
        component: () => import('@/views/maintenance/configList/index.vue'),
        meta: {
          hidden: true,
          title: '配置列表',
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    name: 'NotFound',
    meta: {
      hidden: true,
    },
  },
]

const router = createRouter({
  history: isHashRouterMode
    ? createWebHashHistory(publicPath)
    : createWebHistory(publicPath),
  routes: constantRoutes as RouteRecordRaw[],
})

function fatteningRoutes(routes: VabRouteRecord[]): VabRouteRecord[] {
  return routes.flatMap((route: VabRouteRecord) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

function addRouter(routes: VabRouteRecord[]) {
  routes.forEach((route: VabRouteRecord) => {
    if (!router.hasRoute(route.name)) router.addRoute(route as RouteRecordRaw)
    if (route.children) addRouter(route.children)
  })
}

export function resetRouter(routes: VabRouteRecord[] = constantRoutes) {
  routes.map((route: VabRouteRecord) => {
    if (route.children) route.children = fatteningRoutes(route.children)
  })
  router.getRoutes().forEach(({ name }) => {
    router.hasRoute(<RouteRecordName>name) &&
      router.removeRoute(<RouteRecordName>name)
  })
  addRouter(routes)
}

export function setupRouter(app: any) {
  if (authentication === 'intelligence') addRouter(asyncRoutes)
  setupPermissions(router)
  app.use(router)
  return router
}

export default router
